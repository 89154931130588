// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC3w_YbgE_8VNEQQrXFmvH1ZFmC--rvIls",
    authDomain: "appletree-auth.firebaseapp.com",
    projectId: "appletree-auth",
    storageBucket: "appletree-auth.appspot.com",
    messagingSenderId: "178554146377",
    appId: "1:178554146377:web:dd5f661a7ca8da2c6a7998",
    measurementId: "G-84T62387JH"
};

export default firebaseConfig;
