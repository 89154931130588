import React, { useState, useCallback } from "react";
import AppContext, { message } from "./app-context";
import firebase from "../firebase";
import { useHistory } from "react-router-dom";

const AppContextProvider: React.FC = (props) => {
  const [token, setToken] = useState<string | null>(null);
  const [message, setMessage] = useState<message | null>(null);
  const [user, setuser] = useState<any>(null);
  const history = useHistory();

  const login = async (username: string, password: string) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(async (res) => {
        const idToken = await res.user?.getIdToken();
        if (idToken) {
          setToken(idToken?.toString());
          setuser(res);
          console.log(res);
          
          localStorage.setItem("token", idToken.toString());
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.message });
      });
  };

  const forgotPassword = async (username: string) => {
    firebase
      .auth()
      .sendPasswordResetEmail(username)
      .then(async (res) => {
        console.log(res);
        setMessage({
          type: "success",
          text: "Password receover email sent! Please check your mailbox.",
        });
        history.push("/login");
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.message });
      });
  };

  const signup = async (
    username: string,
    clientId: string,
    password: string,
    confirmPassword: string
  ) => {
    
    if (!clientId || clientId !== new Date().toISOString().slice(0,10).replace(/-/g,"")) {
      setMessage({
        type: "error",
        text: "Invalid client ID. Please try with correct details.",
      });
      return;
    }

    if (password.trim() !== confirmPassword.trim()) {
      setMessage({
        type: "error",
        text: "Password and Confirm Password don't match",
      });
      return;
    }

    //TODO: Validate token using APIs

    firebase
      .auth()
      .createUserWithEmailAndPassword(username, password)
      .then(async (res) => {
        setMessage({ type: "success", text: "User added successfully!" });
        history.push('/login');
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.message });
      });
  };

  const initContext = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }
  }, []);

  const logout = async () => {
    localStorage.removeItem("token");
    setToken(null);
    history.push('/login');
  };

  return (
    <AppContext.Provider
      value={{
        login,
        forgotPassword,
        signup,
        initContext,
        logout,
        token,
        message,
        user,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
