import React from "react";

export interface message {
  text: string;
  type: "error" | "success" | "info" | "warning";
}


export interface Context {
  authenticated?: boolean;
  token: string | null;
  message: message | null;
  login: (username: string, password: string) => void;
  forgotPassword: (username: string) => void;
  signup: (username: string, clientId:string,  password: string, confirmPassword: string) => void;
  initContext: () => void;
  logout: () => void;
  user: any;

}

const AppContext = React.createContext<Context>({
  authenticated: false,
  token: '',
  message: {} as message,
  user: {},
  login: () => {},
  forgotPassword: () => {},
  signup: () => {},
  initContext: () => {},
  logout: () => {},
});

export default AppContext;
