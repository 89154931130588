import React, { useState, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import MuiButton from "@material-ui/core/Button";
import { spacing } from "@material-ui/system";
import "firebase/auth";
import "firebase/firestore";
import AppContext from "../../Data/app-context";
import {
  Box,
  Container,
  Typography,
  Grid,
  styled,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 400,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: 0,
      flexGrow: 1,
      float: "right",
      marginRight: 0,
    },
    header: {
      textAlign: "center",
      background: "#212121",
      color: "#fff",
    },
    card: {
      marginTop: theme.spacing(10),
    },
  })
);


const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const [username, setUserName] = useState("");
  const appContext = useContext(AppContext);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    appContext.forgotPassword(username);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Box mb={3} className={classes.card} width="100%">
            <Typography color="textPrimary" variant="h4" align="center">
              Password Recovery
            </Typography>
          </Box>

          <Box mt={3} mb={1} width="100%">
            <Typography align="center" color="textSecondary" variant="body1">
              Enter your username to receive password reset email
            </Typography>
          </Box>

          {appContext.message && (
            <Snackbar
              open={true}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity={appContext.message.type}>
                {appContext.message.text}
              </Alert>
            </Snackbar>
          )}
          <TextField
            fullWidth
            id="username"
            type="email"
            onChange={(e) => setUserName(e.target.value)}
            label="Username"
            placeholder="Username"
            margin="normal"
            variant="outlined"
          />

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={3}>
              <Button
                mr={2}
                variant="contained"
                size="medium"
                color="primary"
                className={classes.loginBtn}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
