import {
  IconButton,
  Button,
  makeStyles,
  Typography,
  Hidden,
  Tooltip,
} from "@material-ui/core";
import { AppBar, Toolbar } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AppContext from "../../Data/app-context";
import { useContext } from "react";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12,
  },
}));

interface topBar {
  onMobileNavOpen: any;
}

const TopBar = ({ onMobileNavOpen }: topBar) => {
  const classes = useStyles();
  
  return (
    <header>
      <AppBar className={clsx(classes.root)} elevation={0}>
        <Toolbar>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/appletree-auth.appspot.com/o/1548316261.svg?alt=media&token=d989d1fd-3fda-4553-850f-cd8bdbd9cffd"
            height="40px"
          />
          <Typography variant="h5" color="inherit">
            Apple Tree
          </Typography>
          <section className={classes.rightToolbar}>
            {/* <Tooltip title="Logout" aria-label="logout">
              <IconButton
                color="inherit"
                aria-label="Log out"
                onClick={handleLogout}
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip> */}
          </section>
          <Hidden xsDown>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </header>
  );
};
export default TopBar;
