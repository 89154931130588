import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import AppContext from "../../Data/app-context";
import Signup from "../Login/Signup";
import PageNotFound from "../Error/PageNotFound";
import InternalServerError from "../Error/InternalServerError";
import RouteAuthenticated from "./AuthenticatedRoute";
import ForgotPassword from "../Login/ForgotPassword";

const App: React.FC = () => {
  const appContext = useContext(AppContext);
  const [] = useState(false);

  useEffect(() => {
    appContext.initContext();
  }, [appContext.initContext]);

  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route exact path="/logout" render={() => {
              appContext.logout();
                return <></>;
            }
        }/>
        <Route path="/forgotpassword" component={ForgotPassword} />
        <RouteAuthenticated path="/dashboard" component={Dashboard} />
        <RouteAuthenticated path="/404" component={PageNotFound} />
        <RouteAuthenticated path="/500" component={InternalServerError} />
        <RouteAuthenticated path="/" component={Dashboard} />
      </Switch>
    </Container>
  );
};
export default App;
