import React from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 64,
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
}));

const InternalServerError = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography align="center" color="textPrimary" variant="h1">
            500!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" color="textPrimary" variant="h3">
            Internal server error
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" color="textPrimary" variant="h6">
            Something is wrong with something! Someone will fix it when they have some time.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InternalServerError;
